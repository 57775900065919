<template>

  <div style="background-color: white;">
    <van-sticky>
      <van-nav-bar right-text="分享"
                   @click-right="onClickShare">

        <template #title>
          <span style="font-weight:600;color:#0af">Go岗位</span>
        </template>
        <template #left>
          <van-image radius="18px"
                     height="40px"
                     width="40px"
                     @click="txClick"
                     src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg" />
        </template>
      </van-nav-bar>

      <van-cell>
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-row>
            <van-col span="16"
                     style="font-weight: bold;">
              <van-tag plain
                       type="danger"
                       v-if="postDetail.urgentStatus === '1'">急</van-tag> {{postDetail.post}}
            </van-col>
            <van-col span="8"
                     style="text-align: right;color: brown;">{{postDetail.salary}}</van-col>
          </van-row>

          <van-row>
            <van-col span="14">
              {{postDetail.company}}
            </van-col>
            <!-- <van-col v-if="postDetail.bonus"
                     span="10"
                     style="text-align: right;color: red;">推荐入职得：{{postDetail.bonus}}</van-col> -->
          </van-row>

        </template>

        <template #label>

          <van-row type="flex"
                   gutter="2">
            <van-col span="3"><van-tag size="medium"
                       type="warning">{{postDetail.postCity}}</van-tag></van-col>
            <van-col span="3"><van-tag size="medium"
                       type="primary">{{postDetail.education}}</van-tag></van-col>
            <van-col span="18"
                     style="text-align: right;"> {{postDetail.address}}</van-col>
          </van-row>
          <!-- <van-row type="flex">

          <van-col span="24"
                   style="text-align: right;"> <van-button type="info"
                        size="mini"
                        @click="onClickShare">分享岗位</van-button></van-col>

        </van-row> -->

        </template>
      </van-cell>
    </van-sticky>
    <!-- 居中 -->

    <!-- <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"></van-divider> -->
    <!-- <van-row type="flex"
             justify="center">
      <van-col span="22">
        <iframe :src="postDetail.videoUrl"
                width="100%"
                height="220"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"> </iframe>

      </van-col>
    </van-row> -->
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      职位描述
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="postDetail.detail != ''"
             class="markdown-body">
          <viewer ref="markdownViewer"
                  :options="viewerOptions"
                  :height="'auto'"
                  :initialValue="postDetail.detail"></viewer>
        </div>
        <div v-else>
          <van-empty description="岗位详情整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      投递及咨询
    </van-divider>
    <div style="background:#fff"
         align="center">
      <van-image fit="scale-down"
                 width="240px"
                 src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/temp122.png">
        <template v-slot:loading>
          <van-loading type="spinner"
                       size="20"
                       color="#1989fa">加载中...</van-loading>
        </template>
      </van-image>
    </div>
    <!-- <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row> -->

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { ImagePreview, Icon, Sticky, Button, Dialog, ShareSheet, Loading, NavBar, Divider, Empty, Cell, Tag, Col, Row, Image as VanImage, } from 'vant';
const axios = require("axios");
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import '../css/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';


export default {

  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Icon.name]: Icon,
    [Sticky.name]: Sticky,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [ShareSheet.name]: ShareSheet,
    viewer: Viewer,
    [VanImage.name]: VanImage,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    // Valine,
    [Empty.name]: Empty,

    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,

  },

  data () {
    return {
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],

      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },
      id: '',
      postDetail: {
        "address": "雁塔区华为西安研究所锦业路127号",
        "bonus": "2000",
        "company": "华为",
        "companyCity": "西安",
        "createTime": "2022-01-08",
        "education": "本科",
        "experience": "校招",
        "id": 31,
        "memo": "",
        "post": "开发",
        "postCity": "重庆",
        "pushName": "MR.Tan",
        "salary": "16K*14薪",
        "urgentStatus": "0",
        "detail": ""
      }

    };
  },
  created () {
    this.id = this.$route.query.id
    //设置邀请码
    if (this.$route.query.code !== undefined) {
      window.localStorage.setItem('code', this.$route.query.code)
    }
    this.initData()

  },
  methods: {
    initData () {
      axios
        .get("https://api.golangroadmap.com/gopherclub/m/post/detail?id=" + this.id)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.postDetail = response.data.data
          } else {
            console.log(response.data.msg);

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //点击头像进入服务页
    txClick () {
      this.$router.push('post');
    },
    //分享
    onSelect (shareOption) {
      if (shareOption.name == '分享海报') {

        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/post_' + this.id + '.jpg'

        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };
      } else {
        Dialog({ message: '岗位信息链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')
        var shareUrl = "这里有一个靠谱的Go岗位分享给你：https://m.golangroadmap.com/#/sharepost?id=" + this.id + "&code=" + invitationCode + " 。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
    //打开分享
    onClickShare () {
      this.showShare = true
    },
  }
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
